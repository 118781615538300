import styled, { createGlobalStyle } from 'styled-components';
import styledNormalize from 'styled-normalize';
import { css } from 'styled-components';

const fontStyles = `
    @import url('https://fonts.googleapis.com/css?family=ABeeZee:400,400i|Alegreya+Sans:100,100i,300,300i,400,400i,500,500i,700,700i,800,800i,900,900i|Audiowide|Contrail+One|Faster+One|Fira+Code:300,400,500,600,700|Fira+Mono:400,500,700|IBM+Plex+Mono:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i|Knewave|Lobster|Luckiest+Guy|Major+Mono+Display|Megrim|Monoton|Righteous|Titan+One&display=swap');
    font-family: 'Righteous', cursive;
    font-family: 'ABeeZee', sans-serif; //<- cool
    font-family: 'Alegreya Sans', sans-serif; //<- cool
    font-family: 'Luckiest Guy', cursive; // vlt. cool fuer headlines
    font-family: 'IBM Plex Mono', monospace; // schlichte aber sleeke mono
    font-family: 'Fira Mono', monospace; // auch uebsch, ist aber schwerer zu lesen
    font-family: 'Fira Code', monospace; // funktioniert bei mir nicht
    font-family: 'Major Mono Display', monospace; // koennte als headline schrift funktionieren, ist aber schwer zu kombinieren, weil sehr zart.
    font-family: 'Lobster', cursive; // irgendwie zu cheesy
    font-family: 'Monoton', cursive; // wirkt billig
    font-family: 'Audiowide', cursive; // so hat sich irgendjemand die zukunft vorgestellt, aber nicht so cool
    font-family: 'Contrail One', cursive; // ganz huebsch, aber leider nur ein Schnitt
    font-family: 'Titan One', cursive; // sieht gut aus. lustig & trotzdem ernst gemeint. schoen bold
    font-family: 'Knewave', cursive; // sympathische handschrift, koennte funktionieren
    font-family: 'Megrim', cursive; // wirkt ein bisschen 1001 free fonts
    font-family: 'Faster One', cursive; // lustig. wirkt gut dunkel auf hell, andersrum schwierig
`;

const fontStyleTag = document.createElement('style');
fontStyleTag.type = 'text/css';
fontStyleTag.innerHTML = fontStyles;
document.getElementsByTagName('head')[0].appendChild(fontStyleTag);

export const colors = {
    purple: '#3a344d',
    lightTeal: '#7bf0c5',
    almostWhite: '#f0f0f1',
};

export const newColors = {
    jet: '#342E44',
    pineApple: '#4A4163',
    lapisLazuli: '#3369AB',
    middleRed: '#E78778',
    champagnePunk: '#EFD7D7',
    mediumAquamarine: '#5CC9B0',
};

export const pageColors = {
    veryDark1: newColors.jet,
    dark1: newColors.pineApple,
    dark2: newColors.lapisLazuli,
    medium1: newColors.middleRed,
    medium2: newColors.mediumAquamarine,
    light1: newColors.champagnePunk,
};

export const containerStyle = css`
    margin: 40px 0;
    padding: 40px 0;
    overflow: hidden;
    position: relative;
`;

export const boxedContainerStyle = css`
    ${containerStyle}
    background-color: ${pageColors.medium2};
    color: ${pageColors.dark1};
    border-radius: 10px;
    padding: 0;
`;

export const boxedContainerPadding = css`
    padding: 40px ${100 / 7}%;
`;

export const boxedContainerStyleWithPadding = css`
    ${boxedContainerStyle}
    ${boxedContainerPadding}
`;

export const GlobalStyle = createGlobalStyle`
    ${styledNormalize}
`;

const textSelectionStyle = css`
    &::selection {
        background-color: ${pageColors.light1};
        color: ${pageColors.dark2};
    }
`;

export const baseFontStyle = css`
    ${textSelectionStyle}
    font-family: "Alegreya Sans";
    letter-spacing: 0.05em;
    line-height: 1.5em;
    p, span, ul ,li {
        ${textSelectionStyle}
    }
`;

export const headlineStyle = css`
    ${textSelectionStyle}
    font-family: "Alegreya Sans";
    font-weight: 900;
    text-transform: uppercase;
`;

export const Headline2 = styled.h2`
    ${headlineStyle}
`;

export const Headline3 = styled.h3`
    ${headlineStyle}
`;