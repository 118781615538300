import React, { Component } from "react";
import styled, { css } from "styled-components";
import { hot } from "react-hot-loader/root";
import { colors, pageColors } from "styles";
import { Container, Row, Col, Visible } from "react-grid-system";

const keyCapColors = {
  base: pageColors.dark1,
  highlight: pageColors.light1,
  u2: pageColors.medium1,
  space: pageColors.medium2,
  font: pageColors.light1,
};

const easing = "cubic-bezier(0.285, 0.000, 0.145, 1.000)";

const entering = css`
  opacity: 0;
  transform: scale(0);
`;

const entered = css`
  opacity: 1;
  transform: scale(1);
`;

const exiting = css`
  opacity: 0;
  transform: scale(0);
`;

const exited = css`
  opacity: 0;
  transform: scale(0);
`;

const KeyOuterComponent = styled.div`
  position: absolute;
  width: calc(100% / ${props => 15 / props.width});
  height: calc(100% / ${props => 5 / props.height});
  left: ${props => props.columnIndex * (100 / 15)}%;
  top: ${props => props.rowIndex * (100 / 5)}%;
  padding: 3px;
  box-sizing: border-box;
  user-select: none;
  transition: all 0.5s ${easing} 0.5s, transform 0.5s ${easing} 0s,
    opacity 0.5s ${easing} 0s;
  ${props => {
    switch (props.transitionState) {
      case "entering":
        return entering;
      case "entered":
        return entered;
      case "exiting":
        return exiting;
      case "exited":
      default:
        return exited;
    }
  }}
`;

const Cap = styled.div`
  position: relative;
  background-color: ${props=>props.color};
  border-radius: 5px;
  height: 100%;
  padding: 7px;
  box-sizing: border-box;
  transition: background-color 0.5s ${easing} 0.5s;
`;

const CapTop = styled.div`
  background-color: white;
  opacity: 0.1;
  border-radius: 3px;
  height: 100%;
  position: relative;
`;

const LegendContainer = styled.div`
  position: absolute;
  text-align: center;
  top: 7px;
  left: 7px;
  width: calc(100% - 14px);
  height: calc(100% - 14px);
  box-sizing: border-box;
  text-transform: uppercase;
  font-style: italic;
  font-size: 8px;
  color: ${keyCapColors.font};
  overflow: hidden;
  border-radius: 3px;
`;

class AnimatedKeyCap extends Component {

  render() {
    const {
      rowIndex,
      columnIndex,
      width,
      height,
      legend,
      transitionState
    } = this.props;
    const keyColor = width > 1 || height > 1 ? legend === "" ? keyCapColors.u2 : keyCapColors.space : keyCapColors.base;
    return (
      <KeyOuterComponent
        transitionState={transitionState}
        rowIndex={rowIndex}
        columnIndex={columnIndex}
        width={width}
        height={height}
      >
        <Cap color={keyColor}>
          <CapTop />
          <Visible md lg xl>
            <LegendContainer>{legend}</LegendContainer>
          </Visible>
        </Cap>
      </KeyOuterComponent>
    );
  }
}

export default hot(AnimatedKeyCap);
