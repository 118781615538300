import React, { Component } from "react";
import styled from "styled-components";
import { hot } from "react-hot-loader/root";
import { pageColors, boxedContainerStyle, boxedContainerPadding } from "styles";
import Flickity from "react-flickity-component";
import explodeImage from "../../assets/images/slider/explode.jpg";
import prototypingImage1 from "../../assets/images/slider/p1.jpg";
import prototypingImage2 from "../../assets/images/slider/p2.jpg";
import renderImage1 from "../../assets/images/slider/r1.jpg";
import renderPCBImage1 from "../../assets/images/slider/rpcb1.png";
import renderPCBImage2 from "../../assets/images/slider/rpcb2.png";
import arrowRightIcon from "../../assets/images/icons/arrowRight.png";

const slides = [
  { image: renderImage1, copy: "Lorem Ipsum dolor magna" },
  { image: explodeImage, copy: "Lorem Ipsum dolor magna" },
  { image: prototypingImage1, copy: "Lorem Ipsum dolor magna" },
  { image: prototypingImage2, copy: "Lorem Ipsum dolor magna" },
  {
    image: renderPCBImage1,
    copy: "Lorem Ipsum dolor magna",
    backgroundColor: pageColors.light1,
  },
  {
    image: renderPCBImage2,
    copy: "Lorem Ipsum dolor magna",
    backgroundColor: pageColors.dark2,
  }
];

const DetailSliderContainer = styled.div`
  ${boxedContainerStyle}
`;

const Slide = styled.div`
  width: 100%;
  user-select: none;
`;

const SlideImage = styled.div`
  padding-top: 75%;
  background-color: ${props => props.backgroundColor};
  background-image: url(${props => props.image});
  background-size: cover;
`;

const SlideText = styled.div`
  ${boxedContainerPadding}
`;

const SliderInterfaceContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  padding-top: 75%;
  pointer-events: none;
`;

const nextSlideButtonSize = 64;

const NextSlideButton = styled.div`
  pointer-events: all;
  position: absolute;
  top: calc(50% - ${nextSlideButtonSize / 2}px);
  right: ${props => (props.left ? "auto" : 0)};
  left: ${props => (props.left ? 0 : "auto")};
  height: ${nextSlideButtonSize}px;
  width: ${nextSlideButtonSize / 2}px;
  border-top-left-radius: ${props =>
    props.left ? 0 : nextSlideButtonSize / 2}px;
  border-bottom-left-radius: ${props =>
    props.left ? 0 : nextSlideButtonSize / 2}px;
  border-top-right-radius: ${props =>
    props.left ? nextSlideButtonSize / 2 : 0}px;
  border-bottom-right-radius: ${props =>
    props.left ? nextSlideButtonSize / 2 : 0}px;

  background-color: ${pageColors.light1};

  cursor: pointer;
  transform-origin: ${props => (props.left ? 0 : 100)}% 50%;
  transform: scale(0.75);
  transition: transform 0.15s ease-out;
  &:hover {
    transform: scale(1);
  }
`;

const Arrow = styled.div`
  position: absolute;
  width: 80%;
  height: 40%;
  top: 30%;
  left: ${props=>props.left?5:15}%;
  background-image: url(${arrowRightIcon});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  transform: rotate(${props=>props.left? 180:0.001}deg);
`;

class DetailSlider extends Component {
  constructor(props) {
    super(props);
    this.flkty;
  }

  goToPrevSlide() {
    this.flkty.previous();
  }

  goToNextSlide() {
    this.flkty.next();
  }

  renderSlides() {
    return slides.map((slide, index) => (
      <Slide key={index}>
        <SlideImage
          backgroundColor={slide.backgroundColor}
          image={slide.image}
        />
        <SlideText>{slide.copy}</SlideText>
      </Slide>
    ));
  }

  render() {
    const sliderOptions = {
      cellAlign: "left",
      contain: false,
      prevNextButtons: false,
      pageDots: false,
      selectedAttraction: 0.075,
      friction: 0.5,
      dragThreshold: 15,
      wrapAround: true
    };
    return (
      <DetailSliderContainer>
        <Flickity flickityRef={c => (this.flkty = c)} options={sliderOptions}>
          {this.renderSlides()}
        </Flickity>
        <SliderInterfaceContainer>
          <NextSlideButton
            key={"left"}
            left
            onClick={this.goToPrevSlide.bind(this)}
          >
            <Arrow left />
          </NextSlideButton>
          <NextSlideButton
            key={"right"}
            onClick={this.goToNextSlide.bind(this)}
          >
            <Arrow />
          </NextSlideButton>
        </SliderInterfaceContainer>
      </DetailSliderContainer>
    );
  }
}

export default hot(DetailSlider);
