import React, { Component } from "react";
import styled from "styled-components";
import { hot } from "react-hot-loader/root";
import { GlobalStyle, colors, containerStyle, Headline2 } from "styles";

const NextStepsContainer = styled.div`
    ${containerStyle}
`;

class NextSteps extends Component {
  render() {
    return (
      <NextStepsContainer>
        <Headline2>Next Steps</Headline2>
        <ul>
            <li>Lorem Ipsum</li>
            <li>Dolor magna</li>
            <li>Et cetera</li>
        </ul>
      </NextStepsContainer>
    );
  }
}

export default hot(NextSteps);
