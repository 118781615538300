import React, { Component } from "react";
import styled from "styled-components";
import { hot } from "react-hot-loader/root";
import {
  GlobalStyle,
  colors,
  boxedContainerStyleWithPadding,
  Headline2
} from "styles";

const FollowUpContainer = styled.div`
  ${boxedContainerStyleWithPadding}
`;

class FollowUp extends Component {
  render() {
    return <FollowUpContainer><Headline2>Follow up</Headline2><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p></FollowUpContainer>;
  }
}

export default hot(FollowUp);
