import React, { Component } from "react";
import styled from "styled-components";
import { hot } from "react-hot-loader/root";
import { GlobalStyle, colors, containerStyle, Headline2, Headline3 } from "styles";
import jonasPNG from '../../assets/images/portraits/jonas.png';
import christopherPNG from "../../assets/images/portraits/christopher.png";

const AboutUsContainer = styled.div`
    ${containerStyle}
`;
const PersonColumn = styled.div`
    width: ${(3 * 100) / 7}%;
    float: left;
    margin-left: ${props => props.offset * 100 / 7}%;
`;
const Face = styled.div`
    background-color: #333;
    padding-top: 100%;
    background-image: url(${props=>props.image});
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 10px;
`;

class AboutUs extends Component {
  render() {
    return (
      <AboutUsContainer>
        <Headline2>About us</Headline2>
        <PersonColumn>
          <Face image={jonasPNG} />
          <Headline3>Jonas</Headline3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
        </PersonColumn>
        <PersonColumn offset={1}>
          <Face image={christopherPNG} />
          <Headline3>Christopher</Headline3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
        </PersonColumn>
      </AboutUsContainer>
    );
  }
}

export default hot(AboutUs);
