import React, {Component} from 'react';
import styled from 'styled-components';
import { hot } from 'react-hot-loader/root';
import { GlobalStyle, colors, pageColors, baseFontStyle } from 'styles';
import { setConfiguration, Container, Row, Col, ScreenClassProvider } from 'react-grid-system';
import LogersImage from 'assets/images/Logers.png';
import AnimatedKeyboard from '../AnimatedKeyboard';
import Announcement from "../Announcement";
import DetailSlider from '../DetailSlider';
import NextSteps from '../NextSteps';
import AboutUs from '../AboutUs';
import FollowUp from '../FollowUp';

setConfiguration({ defaultScreenClass: 'sm', gridColumns: 15, gutterWidth: 6 });

const MainPageContainer = styled.div`
    ${baseFontStyle}
    background-color: ${pageColors.dark1};
    position: fixed;
    width: 100%;
    height: 100%;
    color: ${pageColors.light1};
    overflow-y: scroll;
`;

const Logo = styled.div`
    background-image: url(${LogersImage});
    background-size: 100%;
    background-repeat: no-repeat;
    padding-top: 42%;
    margin: 50px 0;
`;

const Header = styled.div`
  background-color: ${pageColors.veryDark1};
`;

class Home extends Component{

    componentDidMount() {
        document.title = 'futur.click'
    }

    render() {
        return (
          <ScreenClassProvider>
            <MainPageContainer>
              <GlobalStyle />
              <Header>
                <Container>
                  <Row>
                    <Col offset={{ xs: 1, md: 5 }} xs={13} md={5}>
                      <Logo />
                      {/* <p>We're an independent hobbyist duo striving to create truly next gen input devices.</p> */}
                    </Col>
                  </Row>
                </Container>
              </Header>
              <Container>
                <Row>
                  <Col offset={{ xs: 1, md: 4 }} xs={13} md={7}>
                    <Announcement />
                  </Col>
                </Row>
                <AnimatedKeyboard />
                <Row>
                  <Col offset={{ xs: 1, md: 0 }} xs={13} md={7}>
                    <DetailSlider />
                  </Col>
                  <Col offset={{ xs: 1, md: 1 }} xs={13} md={7}>
                    <NextSteps />
                  </Col>
                </Row>
                <Row>
                  <Col offset={{ xs: 1, md: 0 }} xs={13} md={7}>
                    <AboutUs />
                  </Col>
                  <Col offset={{ xs: 1, md: 1 }} xs={13} md={7}>
                    <FollowUp />
                  </Col>
                </Row>
              </Container>
            </MainPageContainer>
          </ScreenClassProvider>
        );
    }
}

export default hot(Home);
